export const SAVE_PROGRESS_MODAL = "SAVE_PROGRESS_MODAL";
export const VERIFY_GEOJSON_MODAL = "VERIFY_GEOJSON_MODAL";
export const VERIFY_SEMANTIC_MAP_MODAL = "VERIFY_SEMANTIC_MAP_MODAL";
export const REPORT_MODAL = "REPORT_MODAL";
export const VERSION_MODAL = "VERSION_MODAL";
export const CONFIRM_ACTION_MODAL = "CONFIRM_ACTION_MODAL";
export const NEW_PROJECT_MODAL = "NEW_PROJECT_MODAL";
export const EDIT_PROJECT_MODAL = "EDIT_PROJECT_MODAL";
export const POINT_CLOUD_MODAL = "POINT_CLOUD_MODAL";
export const GEO_JSON_SELECT_TYPE_MODAL = "GEO_JSON_SELECT_TYPE_MODAL";
