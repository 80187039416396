import * as React from "react";
import { FC, memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowCounterclockwise, ArrowReturnLeft } from "react-bootstrap-icons";
import {
  MeasureDistanceMode,
  ModifyMode,
  SnappableMode,
} from "@nebula.gl/edit-modes";

import { ALL_MODES, TWO_CLICK_POLYGON_MODES } from "../../constants/nebula";
import {
  Toolbox,
  ToolboxButton,
  ToolboxCheckbox,
  ToolboxControl,
  ToolboxRow,
  ToolboxSelect,
  ToolboxLabel,
} from "../../components/common/toolbox";
import {
  add_deploy_properties_to_geojson,
  verifyFeatures,
  verifyLanes,
  verifyIntersections,
} from "../../utils/toolbox";
import FeatureItem from "../FeatureItem";
import {
  GeoJsonType,
  GeoJsonUploadType,
} from "../../pages/MapEditor/mapEditor";
import MeasureElements from "../MeasureElements";
import { CONFIRM_ACTION_MODAL } from "../../store/slices/modals";
import {
  getMapStructs,
  getOutputMapStructs,
} from "../../store/slices/mapStructs";
import { fetchPatchMapGeoJson } from "../../store/slices/projects";
import { replacer, sortArrayByIndices } from "../../utils";

import { MapToolboxProps, ModeType } from "./index.d";
import { ToolboxControlTextareaStyled, ToolboxTitleStyled } from "./styles";

export const MapToolbox: FC<MapToolboxProps> = memo(
  ({
    geojsonType,
    undoFeatures,
    alterDisabled,
    showGeoJson,
    updateShowGeoJson,
    testFeatures,
    updateFeatures,
    onGeojsonTypeSelect,
    renderMapboxLayer,
    updateRenderMapboxLayer,
    clearSelectedFeatures,
    measureFeatures,
    modeConfig,
    removeMeasureElements,
    toggleModalAction,
    verifySemanticMap,
    undo,
    currentMode,
    updateModeConfig,
    pointsRemovable,
    updatePointsRemovable,
    pointcloudOrigin,
    useLocalCoord,
    radius,
    currentMapProjectId,
    parseStringJson,
    updatePointCloudJson,
    updateMode,
    deleteFeatureProperty,
    selectedFeatureIndexes,
    featureMenuClick,
    updateSelectedFeatureIndexes,
  }) => {
    const dispatch = useDispatch();
    const [pointcloudURL, setPointcloudURL] = useState<string | null>(null);
    const [currentCategory, setCurrentCategory] = useState<string>("View");

    const outputMapStructs = useSelector(getOutputMapStructs);
    const mapStructs = useSelector(getMapStructs);

    const backAction = useCallback(() => {
      window.location.pathname = "/";
    }, []);

    const buildSelectFeatureCheckboxes = useCallback(() => {
      const { features } = testFeatures;
      const checkboxes: JSX.Element[] = [];
      // TODO: Send only required properties
      features.forEach((v, i) =>
        checkboxes.push(
          <FeatureItem
            deleteFeatureProperty={deleteFeatureProperty}
            testFeatures={testFeatures}
            selectedFeatureIndexes={selectedFeatureIndexes}
            featureMenuClick={featureMenuClick}
            updateFeatures={updateFeatures}
            updateSelectedFeatureIndexes={updateSelectedFeatureIndexes}
            key={`feature-item-${i}`}
            index={i}
            featureType={v?.geometry?.type}
          />
        )
      );

      return sortArrayByIndices(checkboxes, selectedFeatureIndexes);
    }, [
      testFeatures,
      deleteFeatureProperty,
      selectedFeatureIndexes,
      featureMenuClick,
      updateFeatures,
      updateSelectedFeatureIndexes,
    ]);

    const buildMode = useCallback(
      ({ note, label, mode }: ModeType, category: string) => {
        if (note) {
          return <ToolboxLabel key={label}>{label}</ToolboxLabel>;
        }

        return (
          <ToolboxButton
            key={label}
            selected={mode === currentMode}
            onClick={() => updateMode(mode, category)}
          >
            {label}
          </ToolboxButton>
        );
      },
      [currentMode, updateMode]
    );

    const saveGeoJson = useCallback(
      (geojsonType: GeoJsonType, hideToast: boolean) => {
        const output = getOutput();

        const file = new Blob([JSON.stringify(output)], {
          type: "octet/stream",
        });

        const fileName = `output_${geojsonType}.geojson`;

        dispatch(
          fetchPatchMapGeoJson({
            file,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            fileName,
            projectId: currentMapProjectId,
            hideToast,
          })
        );
      },
      [
        currentMapProjectId,
        fetchPatchMapGeoJson,
        mapStructs,
        testFeatures,
        pointcloudOrigin,
        useLocalCoord,
        radius,
        testFeatures,
        outputMapStructs,
      ]
    );

    const downloadGeoJSON = useCallback(() => {
      const output = getOutput();

      // Check all necessary conditions before downloading GeoJSON
      if (
        !verifyIntersections(mapStructs.intersections) ||
        !verifyFeatures(testFeatures.features) ||
        !verifyLanes(mapStructs.lanes)
      ) {
        return;
      }

      // Create a Blob object and trigger file download
      const blob = new Blob([JSON.stringify(output, replacer)], {
        type: "octet/stream",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "nebula.geojson";
      link.click();
    }, [
      mapStructs,
      testFeatures,
      pointcloudOrigin,
      useLocalCoord,
      radius,
      outputMapStructs,
    ]);

    const downloadPB = useCallback(() => {
      // Verify necessary conditions for downloading
      if (
        !verifyIntersections(mapStructs.intersections) ||
        !verifyFeatures(testFeatures.features) ||
        !verifyLanes(mapStructs.lanes)
      ) {
        return;
      }

      // Save the Semantic GeoJSON
      saveGeoJson(GeoJsonType.Semantic, true);

      // Create and trigger download link
      const projectId =
        currentMapProjectId || window.location.pathname.split("/")[2];
      const link = document.createElement("a");
      link.href = `/api/v1/map-projects/${projectId}/semantic_map.pb.bin`;
      link.download = "semantic_map.pb.bin";
      document.body.appendChild(link);
      link.click();
      link.remove();
    }, [
      verifyIntersections,
      verifyFeatures,
      mapStructs,
      testFeatures,
      saveGeoJson,
    ]);

    const getOutput = useCallback(
      () => ({
        type: "FeatureCollection",
        properties: {
          latLngOrigin: pointcloudOrigin,
          useLocalCoord: useLocalCoord,
          radius: radius,
        },
        features: testFeatures.features,
        map_structs: outputMapStructs,
      }),
      [pointcloudOrigin, useLocalCoord, radius, testFeatures, outputMapStructs]
    );

    const loadSample = useCallback(
      (type: string, mergeType?: GeoJsonUploadType) => {
        if (type === "geojson") {
          const el = document.createElement("input");
          el.type = "file";
          el.onchange = (e) => {
            const eventTarget = e.target as HTMLInputElement;
            if (eventTarget.files && eventTarget.files[0]) {
              const reader = new FileReader();
              reader.onload = ({ target }) => {
                parseStringJson(target?.result as string, mergeType);
              };
              reader.readAsText(eventTarget.files[0]);
            }
          };
          el.click();
        } else if (type === "ply") {
          const el = document.createElement("input");
          el.type = "file";
          el.onchange = (e) => {
            const eventTarget = e.target as HTMLInputElement;
            if (eventTarget.files && eventTarget.files[0]) {
              const reader = new FileReader();
              reader.readAsArrayBuffer(eventTarget.files[0]);
              reader.onload = ({ target }) => {
                const blob = new Blob([target?.result as BlobPart], {
                  type: "application/octet-stream",
                });
                if (pointcloudURL) {
                  window.webkitURL.revokeObjectURL(pointcloudURL);
                }
                const currentPointcloudURL =
                  window.webkitURL.createObjectURL(blob);
                setPointcloudURL(currentPointcloudURL);
                updatePointCloudJson(currentPointcloudURL);
              };
            }
          };
          el.click();
        }
      },
      [pointcloudURL]
    );

    const deploy = useCallback(() => {
      // overwrite the geojson coordinate info when download
      // No need to re-render
      const output = add_deploy_properties_to_geojson(getOutput());

      console.log(output);

      // TODO: Call API to Generate files
      // this.props.dispatch(
      //  deployMapGeoJson({ file, projectId: this.props.currentMapProjectId })
      // );
    }, [
      pointcloudOrigin,
      useLocalCoord,
      radius,
      testFeatures,
      outputMapStructs,
    ]);

    const renderSnappingControls = useCallback(
      () => (
        <ToolboxRow key="snap">
          <ToolboxTitleStyled>Enable snapping</ToolboxTitleStyled>
          <ToolboxControl>
            <ToolboxCheckbox
              checked={Boolean(modeConfig && modeConfig.enableSnapping)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                updateModeConfig({
                  enableSnapping: Boolean(event.target.checked),
                })
              }
            />
          </ToolboxControl>
        </ToolboxRow>
      ),
      [modeConfig, updateModeConfig]
    );

    const renderMeasureDistanceControls = useCallback(
      () => (
        <ToolboxRow key="measure-distance">
          <ToolboxTitleStyled>Units</ToolboxTitleStyled>
          <ToolboxControl>
            <ToolboxSelect
              value={modeConfig?.turfOptions?.units}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                updateModeConfig({
                  turfOptions: { units: event.target.value },
                })
              }
              options={["meters", "miles", "degrees", "radians"]}
            ></ToolboxSelect>
          </ToolboxControl>
        </ToolboxRow>
      ),
      [modeConfig, updateModeConfig]
    );

    const renderModifyModeControls = useCallback(
      () => (
        <ToolboxRow key="modify">
          <ToolboxTitleStyled>Allow removing points</ToolboxTitleStyled>
          <ToolboxControl>
            <ToolboxCheckbox
              checked={pointsRemovable}
              onChange={() => updatePointsRemovable()}
            />
          </ToolboxControl>
        </ToolboxRow>
      ),
      [pointsRemovable, updatePointsRemovable]
    );

    const renderTwoClickPolygonControls = useCallback(
      () => (
        <ToolboxRow key="twoClick">
          <ToolboxTitleStyled>Drag to draw</ToolboxTitleStyled>
          <ToolboxControl>
            <ToolboxCheckbox
              checked={Boolean(modeConfig && modeConfig.dragToDraw)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                updateModeConfig({
                  dragToDraw: Boolean(event.target.checked),
                })
              }
            />
          </ToolboxControl>
        </ToolboxRow>
      ),
      [modeConfig, updateModeConfig]
    );

    const renderModeConfigControls = useCallback(() => {
      const controls: JSX.Element[] = [];

      if (TWO_CLICK_POLYGON_MODES.indexOf(currentMode) > -1) {
        controls.push(renderTwoClickPolygonControls());
      }
      if (currentMode === ModifyMode) {
        controls.push(renderModifyModeControls());
      }
      if (currentMode instanceof SnappableMode) {
        controls.push(renderSnappingControls());
      }
      if (currentMode === MeasureDistanceMode) {
        controls.push(renderMeasureDistanceControls());
      }

      return controls;
    }, [currentMode]);

    return (
      <Toolbox>
        <ToolboxButton onClick={backAction}>
          <ArrowReturnLeft /> Back
        </ToolboxButton>
        <ToolboxButton disabled={undoFeatures.length === 0} onClick={undo}>
          <ArrowCounterclockwise /> Undo
        </ToolboxButton>
        {ALL_MODES.map((category: { category: string; modes: ModeType[] }) => {
          const disabled = category.category === "Alter" && alterDisabled;
          return (
            <ToolboxRow key={category.category}>
              <ToolboxTitleStyled
                isDisabled={disabled}
                onClick={() =>
                  !disabled && setCurrentCategory(category.category)
                }
                active={category.category === currentCategory}
              >
                {category.category} Modes
              </ToolboxTitleStyled>
              {category.category === currentCategory &&
                category.modes.map((mode) =>
                  buildMode(mode, category.category)
                )}
              {category.category === "View" &&
                measureFeatures.features.length !== 0 && (
                  <MeasureElements
                    units={modeConfig?.turfOptions?.units}
                    features={[...measureFeatures.features]}
                    removeMeasureElements={removeMeasureElements}
                  />
                )}
            </ToolboxRow>
          );
        })}
        {renderModeConfigControls()}
        {showGeoJson && (
          <React.Fragment>
            <ToolboxTitleStyled>GeoJSON</ToolboxTitleStyled>
            <ToolboxButton onClick={() => updateShowGeoJson()}>
              hide &#9650;
            </ToolboxButton>
            <ToolboxControl>
              <ToolboxControlTextareaStyled
                id="geo-json-text"
                rows={5}
                value={JSON.stringify(testFeatures)}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                  updateFeatures(JSON.parse(event.target.value))
                }
              />
            </ToolboxControl>
          </React.Fragment>
        )}
        <ToolboxRow>
          <ToolboxTitleStyled
            onClick={() => setCurrentCategory("Data")}
            active={currentCategory === "Data"}
          >
            Data
          </ToolboxTitleStyled>
          {currentCategory === "Data" && (
            <>
              <ToolboxButton onClick={() => deploy()}>
                Edit and Deploy
              </ToolboxButton>
              <ToolboxButton onClick={() => downloadGeoJSON()}>
                Download Semantic GeoJSON
              </ToolboxButton>
              <ToolboxButton onClick={() => downloadPB()}>
                Download .pb.bin
              </ToolboxButton>
              <ToolboxButton onClick={() => verifySemanticMap()}>
                Check Map Readiness
              </ToolboxButton>
              {geojsonType === GeoJsonType.Centerline && (
                <ToolboxControl>
                  <ToolboxButton onClick={() => loadSample("geojson")}>
                    Open Centerline GeoJSON
                  </ToolboxButton>
                  <ToolboxButton
                    onClick={() => saveGeoJson(GeoJsonType.Centerline, false)}
                  >
                    Save Centerline GeoJSON
                  </ToolboxButton>
                </ToolboxControl>
              )}
              {geojsonType === GeoJsonType.Semantic && (
                <ToolboxControl>
                  <ToolboxButton onClick={() => loadSample("geojson")}>
                    Open Semantic GeoJSON
                  </ToolboxButton>
                  {testFeatures.features.length !== 0 && (
                    <ToolboxButton
                      // onClick={() =>
                      //   loadSample("geojson", GeoJsonUploadType.GeoJsonUploadType)
                      // }
                      onClick={() =>
                        toggleModalAction({
                          type: CONFIRM_ACTION_MODAL,
                          data: {
                            text: "Would you like to merge with feature properties?",
                            confirmAction: () =>
                              loadSample(
                                "geojson",
                                GeoJsonUploadType.MergeWithFeatures
                              ),
                            cancelAction: () =>
                              loadSample(
                                "geojson",
                                GeoJsonUploadType.MergeWithNoFeatures
                              ),
                          },
                        })
                      }
                    >
                      Merge geojson
                    </ToolboxButton>
                  )}
                  <ToolboxButton
                    onClick={() => saveGeoJson(GeoJsonType.Semantic, false)}
                  >
                    Save Semantic GeoJSON
                  </ToolboxButton>
                </ToolboxControl>
              )}

              <ToolboxControl>
                <ToolboxButton
                  disabled
                  title="Coming soon..."
                  onClick={() => loadSample("ply")}
                >
                  Open pointcloud map...
                </ToolboxButton>
              </ToolboxControl>
            </>
          )}
        </ToolboxRow>
        <ToolboxRow>
          <ToolboxTitleStyled
            onClick={() => setCurrentCategory("Features")}
            active={currentCategory === "Features"}
          >
            Features
          </ToolboxTitleStyled>
          {currentCategory === "Features" && (
            <>
              <ToolboxButton onClick={() => clearSelectedFeatures()}>
                Clear Selection
              </ToolboxButton>
              <ToolboxRow>{buildSelectFeatureCheckboxes()}</ToolboxRow>
            </>
          )}
        </ToolboxRow>
        <ToolboxRow>
          <ToolboxTitleStyled
            onClick={() => setCurrentCategory("Layers")}
            active={currentCategory === "Layers"}
          >
            Map Layers
          </ToolboxTitleStyled>
          {currentCategory === "Layers" && (
            <>
              <ToolboxButton
                key={"RenderMapbox"}
                onClick={() => {
                  updateRenderMapboxLayer();
                }}
              >
                {renderMapboxLayer ? "Disable Mapbox" : "Enable Mapbox"}
              </ToolboxButton>
              <ToolboxButton
                onClick={() => onGeojsonTypeSelect(GeoJsonType.Semantic)}
                disabled={geojsonType === GeoJsonType.Semantic}
              >
                {geojsonType === GeoJsonType.Semantic
                  ? "Semantic selected"
                  : "Select Semantic"}
              </ToolboxButton>
              <ToolboxButton
                onClick={() => onGeojsonTypeSelect(GeoJsonType.Centerline)}
                disabled={geojsonType === GeoJsonType.Centerline}
              >
                {geojsonType === GeoJsonType.Centerline
                  ? "Centerline selected"
                  : "Select Centerline"}
              </ToolboxButton>
            </>
          )}
        </ToolboxRow>
      </Toolbox>
    );
  }
);
