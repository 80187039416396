import { FeaturesInterface } from "../../models/map-interface";
import { generateUUID } from "../../utils";

export const updateMeasureFeatures = (
  measureFeatures: FeaturesInterface,
  mode: { _clickSequence: any; _currentDistance?: any; _currentTooltips?: any }
) => {
  const currentMeasureFeatures = { ...measureFeatures };
  if (mode && mode._clickSequence) {
    const { _clickSequence, _currentDistance, _currentTooltips } = mode;
    if (mode._clickSequence.length === 1) {
      currentMeasureFeatures.features.push({
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: _clickSequence,
        },
        properties: {
          id: generateUUID(),
          type: "measure",
          currentTooltips: _currentTooltips,
          distance: _currentDistance,
          feature_id: 0,
          feature_info_list: [],
        },
      });
    }
    if (mode._clickSequence.length > 1) {
      currentMeasureFeatures.features.slice(-1)[0] = {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: _clickSequence,
        },
        properties: {
          id: generateUUID(),
          type: "measure",
          distance: _currentDistance,
          currentTooltips: _currentTooltips,
          feature_id: 0,
          feature_info_list: [],
        },
      };
    }
  }

  return currentMeasureFeatures;
};

export const getMeasureTooltips = (measureFeatures: FeaturesInterface) => {
  const { features } = measureFeatures;
  const currentTooltips: Array<any> = [];
  features.forEach((feature) => {
    if (feature && feature.properties) {
      currentTooltips.push(...feature.properties.currentTooltips);
    }
  });
  return currentTooltips;
};

export const copyPasteFeature = (
  feature: GeoJSON.Feature,
  targetCoordinates: Array<number>
) => {
  const { geometry } = feature;

  if (geometry.type !== "Polygon" && geometry.type !== "LineString") {
    throw new Error("Unsupported geometry type");
  }

  let minLon = Infinity;
  let minLat = Infinity;
  let coordinates: number[][] = [];

  // Get current top-left corner depending on feature geometry type
  if (geometry.type === "Polygon") {
    coordinates = geometry.coordinates[0];
  } else if (geometry.type === "LineString") {
    coordinates = geometry.coordinates;
  }

  // Find the minimum longitude and latitude
  coordinates.forEach(([lon, lat]) => {
    if (lon < minLon) minLon = lon;
    if (lat < minLat) minLat = lat;
  });

  // Calculate the translation offsets
  const lonOffset = targetCoordinates[0] - minLon;
  const latOffset = targetCoordinates[1] - minLat;

  // Apply the translation to each coordinate
  const newCoordinates = coordinates.map(([lon, lat]) => [
    lon + lonOffset,
    lat + latOffset,
  ]);

  return {
    ...feature,
    geometry: {
      ...geometry,
      coordinates:
        geometry.type === "Polygon" ? [newCoordinates] : newCoordinates,
    },
    properties: {
      ...feature.properties,
      feature_info_list: [],
    },
  };
};
