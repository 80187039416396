import { GeoJsonEditMode } from "@nebula.gl/edit-modes";
import {
  FeaturesInterface,
  LatLngPoint,
  MapStructs,
} from "../../models/map-interface";
import { ContextMenuDataType } from "./components/ContextMenu";

export enum GeoJsonType {
  Semantic = "semantic",
  Centerline = "centerline",
}

export enum GeoJsonUploadType {
  MergeWithFeatures = "mergeWithFeatures",
  MergeWithNoFeatures = "MergeWithNoFeatures",
}

export interface MapEditorProps {
  currentMapData: any;
  isLoading: boolean;
  saveSemanticMapFlag: any;
  mapStructs: MapStructs;
  saveMapGeoJsonAction: any;
  getCurrentMapDataAction: any;
  setGeojsonTypeAction: any;
  toggleModalAction: any;
  setMapStructsAction: any;
  removeLaneAction: any;
  setLaneAction: any;
  removeIntersectionAction: any;
  dispatch: any;
}

export interface MapEditorState {
  viewport: Record<string, any>;
  testFeatures: FeaturesInterface;
  measureFeatures: FeaturesInterface;
  undoFeatures: FeaturesInterface[];
  mode: any;
  modeView: typeof GeoJsonEditMode;
  modeConfig: any;
  pointsRemovable: boolean;
  selectedFeatureIndexes: number[];
  selectedLaneId: number;
  editContext?: any;
  editHandleType: string;
  selectionTool?: string;
  showGeoJson: boolean;
  showDialog: boolean;
  featureMenu?: {
    index: number;
    x: number;
    y: number;
  };
  pointcloudOrigin: LatLngPoint;
  renderMapboxLayer: boolean;
  useLocalCoord: boolean;
  editPlane: {
    renderEditPlane: boolean;
    elevation: number;
    radius: number;
    color: number[];
  };
  currentCategory: string;
  radiusDialog: boolean;
  radius?: Map<number, number>;
  pointCloudJson: any;
  layerVisibility: any;
  layers: any;
  editableGeoJsonLayer: any;
  editableGeoJsonLayerVisible: boolean;
  editPlaneLayerVisible: boolean;
  pointCloudData: any;
  isLoading: boolean;
  geojsonType: GeoJsonType | null;
  showGeoJsonTypeModal: boolean;
  geoJsonDataIsLoaded: boolean;
  contextMenuData: ContextMenuDataType | null;
  bufferFeature: number | null;
}
