import React, { PureComponent } from "react";
import { connect } from "react-redux";
import * as Utils from "../../utils";
import { PointCloudLayer, TextLayer } from "@deck.gl/layers";
import { COORDINATE_SYSTEM } from "@deck.gl/core";
import { MapController, MapView } from "@deck.gl/core";
import DeckGL from "@deck.gl/react/typed";
import { EditableGeoJsonLayer } from "@nebula.gl/layers";
import { Feature, GeoJsonProperties, Geometry, LineString } from "geojson";
import { cloneDeep } from "lodash";
import window from "window-or-global";
import MapGL from "react-map-gl";
import { WebMercatorViewport } from "@deck.gl/core";
import { registerLoaders } from "@loaders.gl/core";
import update from "immutability-helper";
import { rainbow } from "../../constants/nebula";
import * as MapConst from "../../constants/map-constants"; // Register ply file loader - Used by pointcloud layer for loading ply
import {
  DuplicateMode,
  ElevationMode,
  ModifyMode,
  Polygon,
  Position,
  SnappableMode,
} from "@nebula.gl/edit-modes";
import {
  initialViewport,
  getEditHandleColor,
  getEditHandleTypeFromEitherLayer,
} from "../../constants/nebula";
import { fetchPatchMapGeoJson } from "../../store/slices/projects";
import {
  FeaturesInterface,
  GeoJSONInterface,
  Lane,
  LineInterface,
  PolygonInterface,
} from "../../models/map-interface";
import { edit_plane_layer } from "../../utils/layers";
// TODO comment to open a new modal window
import FeatureDialog from "../../components/FeatureDialog";
import FeatureMenu from "../../components/FeatureMenu";
import LoaderSpinner from "../../components/common/loaderSpinner";
import { MapToolbox } from "../../components/MapToolbox";
import { PLYWorkerLoader } from "@loaders.gl/ply";
import RadiusDialog from "../../components/RadiusDialog";
import { SideMenu } from "../../components/sideMenu/side-menu";

import {
  setGeojsonType,
  getCurrentMap,
  fetchGetCurrentMapData,
} from "../../store/slices/currentMap";
import {
  getIsLoading,
  getSaveSemanticMapFlag,
} from "../../store/slices/appState";
import { calculateCurve } from "../../utils/alter-curved-edge-mode";
import { CustomViewMode } from "../../utils/custom-view-mode";
import { TangentLineConstraintMode } from "../../utils/tangent-line-constraint-mode";
import {
  getFilteredFeatures,
  getLaneFeatures,
  onLaneSegment,
  getDeckColorForFeature,
  snapFeature,
  generateUUID,
  getGeoJsonDataWithoutMeasureFeatures,
} from "../../utils";
import {
  CONFIRM_ACTION_MODAL,
  toggleModal,
  VERIFY_SEMANTIC_MAP_MODAL,
} from "../../store/slices/modals";
import { GeoJsonSelectTypeModal } from "../../components/GeoJsonSelectTypeModal";

import { MapContainerStyled } from "./styles";
import { MapEditorState, GeoJsonUploadType, MapEditorProps } from "./mapEditor";
import {
  copyPasteFeature,
  getMeasureTooltips,
  updateMeasureFeatures,
} from "./utils";
import {
  getMapStructsFromFeatures,
  getFilteredFeaturesProps,
} from "../../utils/data";
import { INVALID_ID_NUMBER } from "../../map-constants.d";
import {
  getMapStructs,
  removeIntersection,
  removeLane,
  setLane,
  setMapStructsData,
} from "../../store/slices/mapStructs";
import { ToastContent, toast } from "react-toastify";
import { ContextMenu, ContextMenuDataType } from "./components/ContextMenu";
//TODO uncomment to open a new modal
// import { FeatureInfoModal } from "../../components/Modals/FeatureInfoModal";

registerLoaders(PLYWorkerLoader);

//DEFAULT FONT SIZE FOR TOOLTIPS FROM @nebula.gl/layers
const PROJECTED_PIXEL_SIZE_MULTIPLIER = 2 / 3;
const TOOLTIP_FONT_SIZE = PROJECTED_PIXEL_SIZE_MULTIPLIER * 32;
const TOOLTIP_FONT_COLOR = [255, 255, 255];

class MapEditorComponent extends PureComponent<MapEditorProps, MapEditorState> {
  currentPointCloudLayers: any;
  measuringFlag: any;
  initializeLayerVisibility(pointCloudData: any) {
    if (!pointCloudData) {
      return {}; // Return an empty object if pointCloudData is undefined
    }

    const pointCloudLayers = this.createPointCloudLayers(pointCloudData);
    const initialLayerVisibility = pointCloudLayers?.reduce(
      (visibilityMap, layer) => {
        (visibilityMap as any)[layer?.id] = true; // Set the initial visibility for each layer to true
        return visibilityMap;
      },
      {}
    );

    return initialLayerVisibility;
  }

  constructor(props: any) {
    super(props);

    const { currentMapData } = props;

    this.state = {
      viewport: {
        ...initialViewport(),
        latitude: currentMapData.latitude,
        longitude: currentMapData.longitude,
      },
      testFeatures: MapConst.getEmptyFeatureCollection(
        currentMapData.latitude,
        currentMapData.longitude
      ),
      measureFeatures: MapConst.getEmptyFeatureCollection(
        currentMapData.latitude,
        currentMapData.longitude
      ),
      undoFeatures: [],
      mode: CustomViewMode,
      modeView: CustomViewMode,
      modeConfig: null,
      currentCategory: "View",
      pointsRemovable: true,
      selectedFeatureIndexes: [],
      selectedLaneId: INVALID_ID_NUMBER,
      editContext: undefined,
      editHandleType: "point",
      selectionTool: undefined,
      showGeoJson: false,
      showDialog: false,
      featureMenu: undefined,
      pointcloudOrigin: {
        latitude: currentMapData.latitude,
        longitude: currentMapData.longitude,
      },
      renderMapboxLayer: true,
      useLocalCoord: false,
      editPlane: {
        renderEditPlane: false,
        elevation: 0,
        radius: 0.0005,
        color: [99, 203, 224, 150],
      },
      radiusDialog: false,
      pointCloudJson: currentMapData.pointCloudJson,
      layerVisibility: {},
      layers: [],
      editableGeoJsonLayer: null,
      editableGeoJsonLayerVisible: true,
      editPlaneLayerVisible: false,
      pointCloudData: [],
      // TODO: add Floor Plan state and initialize
      isLoading: true,
      geojsonType: null,
      showGeoJsonTypeModal: false,
      geoJsonDataIsLoaded: false,
      contextMenuData: null,
      bufferFeature: null,
    };

    this.currentPointCloudLayers = [];
    this.measuringFlag = false;
  }

  async loadData() {
    const { currentMapData, getCurrentMapDataAction } = this.props;

    if (!currentMapData._id) {
      await getCurrentMapDataAction();
      await this.getGeoJsonData();
    } else {
      await this.getGeoJsonData();
    }
    await this.fetchPointCloudData();
  }

  async componentDidMount() {
    await this.loadData();
    this.updateLayers();

    window.addEventListener("resize", this.resize);
    document.addEventListener("keydown", this.handleKeyDown);
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (
      this.props.currentMapData.pointCloudJson !==
      prevProps.currentMapData.pointCloudJson
    ) {
      await this.loadData();
    }

    if (this.state.layerVisibility !== prevState.layerVisibility) {
      // Update the layers only when layerVisibility state changes
      this.updateLayers();
    }
  }

  handleKeyDown = (event: any) => {
    if (
      event.keyCode === MapConst.ESCAPE_KEY &&
      this.state.mode === TangentLineConstraintMode
    ) {
      this.setState({ selectedFeatureIndexes: [] }, () =>
        this.setState({
          editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
        })
      );
    }
  };

  async fetchPointCloudData() {
    this.setState({ isLoading: true });

    try {
      if (!this.props.currentMapData.pointCloudJson) return;
      const response = await fetch(this.props.currentMapData.pointCloudJson);
      const pointCloudData = await response.json();
      this.setState(
        {
          pointCloudData,
          isLoading: false,
          layerVisibility: this.initializeLayerVisibility(pointCloudData),
        },
        this.updateLayers
      );
    } catch (error) {
      toast.error(`fetchPointCloudData: ${error as ToastContent}`);
    }
  }

  // TODO: add Floor Plan data fetch

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  getGeoJsonData = async () => {
    const { currentMapData } = this.props;
    let needParseFile = true;

    if (!currentMapData.semanticMapGeoJson) return;

    const geoJsonData = await fetch(currentMapData.semanticMapGeoJson, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch(() => {
        needParseFile = false;
        return MapConst.fetchMapOrigin();
      });

    if (needParseFile) {
      this.parseStringJson(JSON.stringify(geoJsonData));
    } else {
      this.setState({});
    }

    this.setState({
      viewport: {
        ...initialViewport(),
        latitude: currentMapData.latitude,
        longitude: currentMapData.longitude,
      },
    });
  };

  debugLaneSegmentArea = (
    lanes: Record<string, Lane>,
    features: GeoJSON.Feature<LineString>[],
    id?: number
  ) => {
    const f = { ...this.state.testFeatures };
    f.features = [];

    if (id) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const lane = Utils.makePolygon(lanes[id]!, features);
      f.features.push(lane.polygon);
      // f.features.push(lane.line);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      for (const l of Object.values(lanes)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const lane = Utils.makePolygon(l, features);
        f.features.push(lane.polygon);
      }
    }

    this.setState({ testFeatures: f }, () =>
      console.log("state", this.state.testFeatures)
    );
  };

  onLayerClick = (info: any, event: any) => {
    const { mapStructs } = this.props;
    const { lanes } = mapStructs;
    const { mode, selectionTool, testFeatures } = this.state;
    const features = testFeatures.features as GeoJSON.Feature<LineString>[];
    let selectedLaneId = MapConst.INVALID_ID_NUMBER;
    let selectedFeatureIndexes: number[] = [];
    let contextMenuData: ContextMenuDataType | null = null;

    // Early return if editing or selection tool is active
    if (mode !== CustomViewMode || selectionTool) return;

    const isFeature = info && info.index !== INVALID_ID_NUMBER;

    // Handle feature click
    if (isFeature) {
      // A feature was clicked
      selectedFeatureIndexes = [info.index];
    } else if (info && !MapConst.DEBUG_LANE_SEGMENT) {
      // Handle lane segment selection
      selectedLaneId = onLaneSegment(info, lanes, features);

      if (selectedLaneId !== MapConst.INVALID_ID_NUMBER) {
        const laneInfo = lanes[String(selectedLaneId)];
        selectedFeatureIndexes = getLaneFeatures(
          testFeatures.features,
          laneInfo
        );
      }
    } else {
      // Open space was clicked, deselect feature
      console.log("Deselect editing feature");
    }

    if (event.rightButton) {
      contextMenuData = {
        position: info.pixel,
        coordinate: info.coordinate,
      };
      if (isFeature) {
        contextMenuData.currentBufferFeature = info.index;
      }
    }

    // If debug mode is enabled, show debug info for lane segments
    if (MapConst.DEBUG_LANE_SEGMENT) {
      this.debugLaneSegmentArea(lanes, features);
    } else {
      // Update state in a single setState call to avoid multiple re-renders
      this.setState(
        {
          selectedFeatureIndexes,
          selectedLaneId,
          contextMenuData,
        },
        () =>
          this.setState({
            editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
          })
      );
    }
  };

  addToClipboard = (featureIndex: number) => {
    let bufferFeature = null;

    if (featureIndex !== INVALID_ID_NUMBER) {
      const { testFeatures } = this.state;
      const features = testFeatures.features as GeoJSON.Feature<LineString>[];
      bufferFeature = featureIndex;
      toast.success(
        `The Feature ${
          features[featureIndex]?.properties?.feature_id || ""
        } is added to the clipboard`
      );
    }

    this.setState(
      {
        bufferFeature,
        contextMenuData: null,
      },
      () =>
        this.setState({
          editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
        })
    );
  };

  pasteFromClipboard = (position: Array<number>) => {
    const { testFeatures, bufferFeature } = this.state;

    if (bufferFeature === null) return;

    const features = testFeatures.features as GeoJSON.Feature<
      LineString | Polygon
    >[];
    const newFeature = copyPasteFeature(
      features[bufferFeature],
      position
    ) as GeoJSON.Feature<LineString | Polygon>;

    this.setState(
      {
        testFeatures: {
          ...testFeatures,
          features: [...features, newFeature],
        },
        contextMenuData: null,
      },
      () =>
        this.setState({
          editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
        })
    );
  };

  resize = () => {
    this.forceUpdate();
  };

  updateViewPortWithLatLong(
    lat: number,
    long: number,
    bounding_box: [any, any] | undefined
  ) {
    typeof bounding_box !== "undefined"
      ? bounding_box
      : [
          [-10, -10],
          [10, 10],
        ];
    let xyz_delta = [0, 0];
    if (typeof bounding_box !== "undefined") {
      const [mins, maxs] = bounding_box;
      xyz_delta = [(mins[0] + maxs[0]) / 2, (mins[1] + maxs[1]) / 2];
    }
    const [viewport_center_lon, viewport_center_lat] =
      new WebMercatorViewport().addMetersToLngLat([long, lat], xyz_delta);

    // File contains bounding box info
    const viewport = {
      ...this.state.viewport,
      longitude: viewport_center_lon,
      latitude: viewport_center_lat,
    };
    this.setState({ viewport });
  }

  parseStringJson = (json: string, geoJsonUploadType?: GeoJsonUploadType) => {
    const { setMapStructsAction } = this.props;
    const input: GeoJSONInterface = JSON.parse(json, Utils.reviver);
    const { testFeatures, viewport } = this.state;
    const { features, properties, type } = testFeatures;

    if (geoJsonUploadType === GeoJsonUploadType.MergeWithNoFeatures) {
      const newFeatures = input.features.map(({ geometry, type }) => ({
        type,
        geometry,
        properties: {
          feature_id: generateUUID(),
          feature_info_list: [],
        },
      }));
      this.setState(
        {
          testFeatures: {
            features: [...features, ...newFeatures],
            properties,
            type,
          },
        },
        () =>
          this.setState({
            editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
          })
      );
      return;
    }

    if (geoJsonUploadType === GeoJsonUploadType.MergeWithFeatures) {
      const newFeatures = input.features.map(
        ({ geometry, type, properties }) => ({
          type,
          geometry,
          properties: {
            feature_id: generateUUID(),
            feature_info_list: properties?.feature_info_list || [],
          },
        })
      );

      this.setState(
        {
          testFeatures: {
            features: [...features, ...newFeatures],
            properties,
            type,
          },
        },
        () =>
          this.setState({
            editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
          })
      );
      return;
    }

    try {
      const newFeatures = input.features.map(
        ({ geometry, type, properties }) => ({
          type,
          geometry,
          properties: {
            feature_id: properties?.feature_id
              ? Number(properties?.feature_id)
              : generateUUID(),
            feature_info_list: properties?.feature_info_list || [],
          },
        })
      );
      const newProperties = input.properties || {};
      const { latLngOrigin } = newProperties;

      //TODO: After the Back End starts receiving SpeedLimits from the new source get rid of map_structs
      const speedLimits: any = {};
      if (input && input.map_structs && input.map_structs.lanes) {
        input.map_structs.lanes.forEach(({ lane_id, speed_limit }: any) => {
          if (lane_id && speed_limit) {
            speedLimits[lane_id] = speed_limit;
          }
        });
      }
      const mapStructsData = getMapStructsFromFeatures(
        newFeatures,
        speedLimits
      );
      setMapStructsAction(mapStructsData);

      this.setState(
        {
          testFeatures: {
            type: "FeatureCollection",
            features: getFilteredFeaturesProps(
              newFeatures,
              mapStructsData.errors
            ),
            properties: newProperties,
          },
          // TODO: get Availible IDs by selector,
          // availableIds: getAvailableIdsByLanes(currentMapStructs),
          geoJsonDataIsLoaded: true,
          viewport: {
            ...viewport,
            latitude: latLngOrigin.latitude
              ? latLngOrigin.latitude
              : viewport.latitude,
            longitude: latLngOrigin.longitude
              ? latLngOrigin.longitude
              : viewport.longitude,
          },
        },
        () =>
          this.setState({
            editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
          })
      );

      // Alert if origin mismatch with pointcloud opened
      if (
        this.state.testFeatures.properties.latLngOrigin.latitude !==
          this.state.pointcloudOrigin.latitude ||
        this.state.testFeatures.properties.latLngOrigin.longitude !==
          this.state.pointcloudOrigin.longitude
      ) {
        const msg =
          // eslint-disable-next-line no-template-curly-in-string
          "WARNING: Point cloud and GeoJSON origin mismatch! (Make Point Cloud Map origin is taken from xxx/cyngn_map/${location_id}/metadata.yaml). GeoJSON origin will be used for now.";
        toast.warning(msg);
        const geojson_latlong_origin =
          this.state.testFeatures.properties.latLngOrigin;
        const temp_state = update(this.state, {
          pointcloudOrigin: {
            latitude: { $set: geojson_latlong_origin.latitude },
            longitude: { $set: geojson_latlong_origin.longitude },
          },
        });
        this.setState(temp_state);
        this.updateViewPortWithLatLong(
          geojson_latlong_origin.latitude,
          geojson_latlong_origin.longitude,
          undefined
        );
      }
    } catch (error) {
      toast.error(error as ToastContent);
    }
  };

  handleMouseDown = (e: any) => {
    if (e.button === 2) {
      e.preventDefault();
    }
  };

  adjustEditPlaneElevation(diff: number) {
    const editPlane = update(this.state.editPlane, {
      elevation: { $set: this.state.editPlane.elevation + diff },
    });
    this.setState(() => ({ editPlane }));
  }

  adjustEditPlaneRadius(diff: number) {
    const editPlane = update(this.state.editPlane, {
      radius: { $set: this.state.editPlane.radius + diff },
    });
    this.setState(() => ({ editPlane }));
  }

  setEditPlaneOpen(open: boolean) {
    const editPlane = update(this.state.editPlane, {
      renderEditPlane: { $set: open },
    });
    this.setState(() => ({ editPlane }));
  }

  featurePropIdtoIndex(
    geometry_type: string,
    feature_prop_name: string,
    feature_prop_id: number
  ) {
    for (let i = 0; i < this.state.testFeatures.features?.length; i++) {
      const feature = this.state.testFeatures.features[i];
      if (feature.geometry.type === geometry_type) {
        for (const index in feature.properties?.feature_info_list) {
          if (
            feature.properties?.feature_info_list[index][feature_prop_name] ===
            feature_prop_id
          ) {
            return i;
          }
        }
      }
    }
    return INVALID_ID_NUMBER;
  }

  onLaneFocus(laneIds: Array<number>) {
    const lanesRelatedFeaturesIndexes: Array<number> = [];

    laneIds.forEach((laneId: number) => {
      const { mapStructs } = this.props;
      const laneInfo = mapStructs.lanes[laneId];
      const { controlLines, stopSigns } = mapStructs;
      const controlLineItem = Object.values(controlLines).find(
        (controlLine) => controlLine.associated_lane_id === laneId
      );
      const stopSignItem = Object.values(stopSigns).find(
        (stopSign) => stopSign.associated_lane_id === laneId
      );
      const laneRelatedFeatures = getLaneFeatures(
        this.state.testFeatures.features,
        laneInfo,
        controlLineItem?.control_line_id || undefined,
        stopSignItem?.stop_sign_id || undefined
      );
      lanesRelatedFeaturesIndexes.push(...laneRelatedFeatures);
    });

    this.setState({ selectedFeatureIndexes: lanesRelatedFeaturesIndexes }, () =>
      this.setState({
        editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
      })
    );
  }

  onIntersectionFocus(intersection_id: number) {
    // highlight the intersection polygon
    this.setState({
      selectedFeatureIndexes: [
        this.featurePropIdtoIndex(
          "Polygon",
          MapConst.INTERSECTION_ID_STRING_NAME,
          intersection_id
        ),
      ],
    });
  }

  onStopSignFocus(stop_sign_id: number) {
    const { mapStructs } = this.props;
    // highlight the stop sign polygon and associated control line
    let selectedStopSignsIndexes: number[] = [];
    selectedStopSignsIndexes = selectedStopSignsIndexes.concat(
      this.featurePropIdtoIndex(
        "Polygon",
        MapConst.STOP_SIGN_ID_STRING_NAME,
        stop_sign_id
      )
    );

    const associated_control_line_id: number | undefined =
      mapStructs.stopSigns[stop_sign_id]?.associated_control_line_id;

    if (associated_control_line_id) {
      selectedStopSignsIndexes = selectedStopSignsIndexes.concat(
        this.featurePropIdtoIndex(
          "LineString",
          MapConst.CONTROL_LINE_ID_STRING_NAME,
          associated_control_line_id
        )
      );
    }

    this.setState({ selectedFeatureIndexes: selectedStopSignsIndexes });
  }

  renderStaticMap(viewport: Record<string, any>) {
    if (this.state.renderMapboxLayer)
      return (
        <MapGL
          {...viewport}
          mapboxApiAccessToken="pk.eyJ1IjoieWlhbm5pLXZlcnZlcmlzIiwiYSI6ImNrcWF0azdnejBjdm4yd3M3ajBmb2hpeGkifQ.rl7QWaaMtqRYNJ-vMIMoOA"
          mapStyle={"mapbox://styles/mapbox/dark-v10"}
        />
      );
    else return <div></div>;
  }

  updateUndo = (updatedData: FeaturesInterface) => {
    const undo = [...this.state.undoFeatures];

    undo.push(updatedData);

    if (undo.length > MapConst.MAX_UNDO) {
      undo.shift();
    }

    this.setState({ undoFeatures: undo });
    return undo;
  };

  onEdit = ({
    updatedData,
    editType,
    editContext,
  }: {
    updatedData: FeaturesInterface;
    editType: string;
    editContext: any;
  }) => {
    let updatedSelectedFeatureIndexes = [...this.state.selectedFeatureIndexes];
    let { undoFeatures } = this.state;
    const { measureFeatures, editableGeoJsonLayer, currentCategory } =
      this.state;
    let newUpdatedData = cloneDeep(updatedData);
    const currentMeasureFeatures = { ...measureFeatures };

    if (currentCategory === "View" && editType === "addTentativePosition") {
      const { mode } = editableGeoJsonLayer.state;
      this.setState(
        {
          selectedFeatureIndexes: updatedSelectedFeatureIndexes,
          measureFeatures: updateMeasureFeatures(currentMeasureFeatures, mode),
        },
        () =>
          this.setState({
            editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
          })
      );
      return;
    }

    if (
      ![
        "movePosition",
        "extruding",
        "rotating",
        "translating",
        "scaling",
        "addTentativePosition",
      ].includes(editType)
    ) {
      // Don't log edits that happen as the pointer moves since they're really chatty
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const updatedDataInfo = Utils.featuresToInfoString(newUpdatedData);
      // eslint-disable-next-line
      console.log("onEdit", editType, editContext, updatedDataInfo);
    }

    if (editType === "removePosition" && !this.state.pointsRemovable) {
      // reject the edit
      return;
    }

    if (editType === "addFeature" && this.state.mode !== DuplicateMode) {
      const { featureIndexes } = editContext;

      // Add the new feature to the selection
      updatedSelectedFeatureIndexes = [
        ...this.state.selectedFeatureIndexes,
        ...featureIndexes,
      ];

      newUpdatedData = snapFeature(
        featureIndexes,
        { ...newUpdatedData },
        currentMeasureFeatures.features.length + 1
      );
    }

    if (editType === "addFeature" && this.state.mode === DuplicateMode) {
      updatedSelectedFeatureIndexes.forEach((index) => {
        const currentItem = { ...newUpdatedData.features[index] };
        currentItem.properties = {
          feature_id: generateUUID(),
          feature_info_list: [],
        };
        newUpdatedData.features[index] = currentItem;
      });
    }

    if (editType === "tangentLine") {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        updatedSelectedFeatureIndexes = Utils.tangentLineConstraint(
          this.state.testFeatures.features,
          this.state.selectedFeatureIndexes,
          editContext
        );
      } catch (error: any) {
        toast.error(error.message);
        return;
      }
    }

    if (["removePosition", "addFeature", "addPosition"].includes(editType)) {
      undoFeatures = this.updateUndo(this.state.testFeatures);
    }

    this.setState(
      {
        testFeatures: getGeoJsonDataWithoutMeasureFeatures(newUpdatedData),
        selectedFeatureIndexes: updatedSelectedFeatureIndexes,
        editContext: editContext,
        radiusDialog: !!editContext?.radiusDialog,
        undoFeatures,
        measureFeatures: currentMeasureFeatures,
      },
      () =>
        this.setState({
          editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
        })
    );
  };

  getFillColor = (
    feature: Feature<Geometry, GeoJsonProperties>,
    isSelected: any
  ) => {
    const index = this.state.testFeatures.features?.indexOf(feature);
    return isSelected
      ? getDeckColorForFeature(index, 1.0, 0.5)
      : getDeckColorForFeature(index, 0.5, 0.5);
  };

  getLineColor = (
    feature: Feature<Geometry, GeoJsonProperties>,
    isSelected: any
  ): RGBAColor => {
    const index = this.state.testFeatures.features?.indexOf(feature);

    if (
      feature.properties &&
      feature.properties.type &&
      feature.properties.type === "measure"
    ) {
      return [108, 117, 125, 255];
    }

    return isSelected
      ? getDeckColorForFeature(index, 1.0, 1.0)
      : getDeckColorForFeature(index, 0.5, 1.0);
  };

  updateFeatures = (testFeatures: any) => {
    this.setState({ testFeatures });
  };

  updateModeConfig = (temp_props: any) => {
    this.setState({
      modeConfig: {
        ...(this.state.modeConfig || {}),
        ...temp_props,
      },
    });
  };

  updatePointsRemovable = () => {
    this.setState({ pointsRemovable: !this.state.pointsRemovable });
  };

  updateRenderMapboxLayer = () => {
    this.setState({ renderMapboxLayer: !this.state.renderMapboxLayer });
  };

  deleteFeatureProperty = (featureIndex: number, featureInfoIndex: number) => {
    const { testFeatures } = this.state;
    const { mapStructs, removeIntersectionAction, setLaneAction } = this.props;
    const { lanes } = mapStructs;
    const featureInfoProperties:
      | Array<LineInterface>
      | Array<PolygonInterface> =
      testFeatures.features[featureIndex].properties?.feature_info_list;

    if (!featureInfoProperties) return;

    const removedItem = featureInfoProperties[featureInfoIndex];

    if (
      "polygon_type" in removedItem &&
      removedItem.polygon_type === "intersection"
    ) {
      removeIntersectionAction(removedItem.intersection_id);
    }

    const currentLaneId =
      featureInfoProperties[featureInfoIndex].lane_association;

    if (currentLaneId !== "NULL") {
      const currentLane = { ...lanes[currentLaneId] };

      if (
        currentLane &&
        Object.keys(currentLane).length > 0 &&
        "line_type" in removedItem
      ) {
        const currentLineType = removedItem.line_type;
        if (currentLineType === "lane_left_boundary_line") {
          currentLane.left_boundary_line_id = MapConst.INVALID_ID_NUMBER;
        }
        if (currentLineType === "lane_right_boundary_line") {
          currentLane.right_boundary_line_id = MapConst.INVALID_ID_NUMBER;
        }
        if (currentLineType === "lane_start_line") {
          currentLane.start_line_id = MapConst.INVALID_ID_NUMBER;
        }
        if (currentLineType === "lane_termination_line") {
          currentLane.termination_line_id = MapConst.INVALID_ID_NUMBER;
        }

        setLaneAction(currentLane);
      }
    }

    const filteredInfoProperties = featureInfoProperties.filter(
      (featureInfo: GeoJsonProperties, index: number) =>
        index !== featureInfoIndex && featureInfo
    );

    const updatedFeatures = update(testFeatures, {
      features: {
        [featureIndex]: {
          properties: {
            feature_info_list: { $set: filteredInfoProperties },
          },
        },
      },
    });
    this.setState({
      testFeatures: updatedFeatures,
    });
  };

  removeNullFeatureProperty = (selectedFeatures: Array<number>) => {
    const { features } = this.state.testFeatures;
    const fileredPropertyFeatures = features.map((feature, index) => {
      if (selectedFeatures.includes(index)) {
        return { ...feature };
      } else {
        const filteredFeatureInfoProperties: Feature<
          Geometry,
          GeoJsonProperties
        >[] = feature.properties?.feature_info_list?.filter(
          (featureInfo: GeoJsonProperties) =>
            (featureInfo?.control_line_id !== "NULL" &&
              featureInfo?.lane_association !== "NULL") ||
            (featureInfo?.polygon_type !== "NULL" &&
              featureInfo?.lane_association !== "NULL")
        );

        return {
          ...feature,
          properties: {
            ...feature.properties,
            feature_info_list: filteredFeatureInfoProperties,
          },
        };
      }
    });

    this.updateFeatures({
      ...this.state.testFeatures,
      features: fileredPropertyFeatures,
    });
  };

  updateSelectedFeatureIndexes = (selected: Array<number>, menu?: any) => {
    this.removeNullFeatureProperty(selected);
    this.setState(
      {
        selectedFeatureIndexes: selected,
        featureMenu: menu || this.state.featureMenu,
      },
      () =>
        this.setState({
          editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
        })
    );
  };

  updateShowGeoJson = () => {
    this.setState({ showGeoJson: !this.state.showGeoJson });
  };

  updateMode = (mode: any, category: string) => {
    this.setState(
      {
        mode,
        modeConfig: {
          turfOptions: { units: "meters" },
        },
        selectionTool: undefined,
        currentCategory: category,
      },
      () =>
        this.setState({
          editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
        })
    );
  };

  updateFeatureMenu = (
    testFeatures: any,
    show_dialog: boolean,
    selectedFeatureIndexes: number[],
    undoFeatures: FeaturesInterface[]
  ) => {
    this.setState(
      {
        featureMenu: undefined,
        testFeatures,
        showDialog: show_dialog,
        selectedFeatureIndexes,
        undoFeatures,
      },
      () =>
        this.setState({
          editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
        })
    );
  };

  updateDialog = (showDialog: boolean) => {
    this.setState({ showDialog });
  };

  updateLayers = () => {
    const { editPlane, viewport, pointCloudData } = this.state;

    const pointCloudLayers = this.createPointCloudLayers(pointCloudData);

    const visiblePointCloudLayers = pointCloudLayers.filter((layer) => {
      return this.state.layerVisibility[layer.id];
    });

    const layers = [
      ...(this.state.editableGeoJsonLayerVisible
        ? [this.state.editableGeoJsonLayer]
        : []),
      ...visiblePointCloudLayers,
      ...(this.state.editPlaneLayerVisible
        ? [
            edit_plane_layer(
              [viewport.longitude, viewport.latitude],
              editPlane.elevation,
              editPlane.radius,
              editPlane.color,
              editPlane.renderEditPlane
            ),
          ]
        : []),
    ];

    this.currentPointCloudLayers = this.createPointCloudLayers(pointCloudData);

    this.setState({
      layers,
      editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
    });
  };

  getRadius = () => {
    const index: number = this.state.editContext?.featureIndex;
    const position = this.state.editContext?.positionIndexes?.[0];
    const feature = this.state.testFeatures.features?.[index];

    return feature?.properties?.radius?.get(position);
  };

  getIsError = () => {
    const index: number = this.state.editContext?.featureIndex;
    const position = this.state.editContext?.positionIndexes?.[0];
    const line = this.state.testFeatures.features?.[index]
      ?.geometry as LineString;
    const length = line?.coordinates?.length || 0;

    // Intermediate handle
    if (this.state.editContext?.position) {
      return true;
    }

    return !(position > 0 && length - 1 > position);
  };

  updateRadius = (radiusDialog: boolean, radius?: number) => {
    const index: number = this.state.editContext?.featureIndex;
    const position = this.state.editContext?.positionIndexes?.[0];
    const features = [...this.state.testFeatures.features];
    const feature = cloneDeep(features?.[index]);
    const length = (feature?.geometry as LineString)?.coordinates?.length || 0;
    const vertex = position > length && this.state.editContext?.position;
    let { undoFeatures } = this.state;

    if (radius) {
      if (feature?.properties?.radius) {
        (feature.properties.radius as Map<number, number>).set(
          position,
          radius
        );
      } else {
        feature.properties = {
          radius: new Map([[position, radius]]),
        };
      }

      try {
        (feature.geometry as LineString).coordinates = calculateCurve(
          feature.geometry as LineString,
          radius,
          position,
          vertex
        );
        undoFeatures = this.updateUndo(this.state.testFeatures);
      } catch (error: any) {
        toast.error(error.message);
      }
      features[index] = feature;
    }

    this.setState(() => ({
      radiusDialog,
      testFeatures: {
        ...this.state.testFeatures,
        features,
      },
      undoFeatures,
    }));
  };

  updatePointCloudJson = () => {
    // TODO: INFR-4308 - Call backend to obtain the json url for the point so it can be updated.
    // And createAsyncThunk on cloud-map-editor/src/redux/slices/currentMap.js
  };

  undoFeature = () => {
    const undoFeatures = [...this.state.undoFeatures];
    const testFeatures = undoFeatures.pop();
    if (testFeatures) {
      this.setState({ testFeatures, undoFeatures }, () =>
        this.setState({
          editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
        })
      );
    }
  };

  clearSelectedFeatures = () => {
    this.setState(
      {
        selectedFeatureIndexes: [],
        selectionTool: undefined,
      },
      () =>
        this.setState({
          editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
        })
    );
  };

  // TODO: add toggling of layer for Floor Plan
  isSemanticDataPresent = (state: {
    currentMap: { semanticMapGeoJson: null };
  }) => state.currentMap.semanticMapGeoJson !== null;

  isCenterlineDataPresent = (state: {
    currentMap: { centerlineGeoJson: null };
  }) => state.currentMap.centerlineGeoJson !== null;

  groupPointsByColor = (pointCloudData: { COORDINATES: number[] }[]) => {
    if (!pointCloudData) {
      return {}; // Return an empty object if pointCloudData is undefined
    }

    const groupedPoints: any = {};

    for (let i = 0; i < MapConst.COLOR_COUNT; i++) {
      const color = rainbow().colourAt((i * 20) % 100);
      groupedPoints[color] = [];
    }

    pointCloudData.forEach((point: { COORDINATES: number[] }) => {
      const colorIndex = Math.floor(
        ((point.COORDINATES[2] * 20) % 100) / (100 / MapConst.COLOR_COUNT)
      );
      const color = Object.keys(groupedPoints)[colorIndex];
      if (groupedPoints[color]) {
        groupedPoints[color].push(point);
      }
    });

    return groupedPoints;
  };

  createPointCloudLayers = (pointCloudData: { COORDINATES: number[] }[]) => {
    const groupedPoints = this.groupPointsByColor(pointCloudData);

    // create separate PointCloudLayers for each color group
    const pointCloudLayers: any[] = [];

    Object.entries(groupedPoints).forEach(([color, points], index) => {
      const rgbColor = Utils.hex2rgb(color).map((c) => c * 255);
      const layerId = `point_cloud_layer_${index}`;

      if (points && (points as Array<number>).length !== 0) {
        pointCloudLayers.push(
          new PointCloudLayer({
            id: layerId,
            data: points,
            coordinateSystem: COORDINATE_SYSTEM.LNGLAT_OFFSETS,
            coordinateOrigin: [
              this.props.currentMapData?.longitude,
              this.props.currentMapData?.latitude,
            ],
            getPosition: (d: any) => d.COORDINATES,
            pointSize: 2,
            opacity: 1.0,
            getColor: () => rgbColor,
            visible: this.state.layerVisibility[layerId] || false,
          })
        );
      }
    });

    return pointCloudLayers;
  };

  // TODO: add new creation of layer for Floor Plan
  toggleLayerVisibility = (id: string) => {
    this.setState((prevState) => {
      const newLayerVisibility = {
        ...prevState.layerVisibility,
        [id]: !prevState.layerVisibility[id],
      };

      if (newLayerVisibility[id] !== prevState.layerVisibility[id]) {
        return { layerVisibility: newLayerVisibility };
      } else {
        return null;
      }
    }, this.updateLayers);
  };

  toggleEditableGeoJsonLayerVisibility = () => {
    this.setState(
      (prevState) => ({
        editableGeoJsonLayerVisible: !prevState.editableGeoJsonLayerVisible,
      }),
      this.updateLayers
    );
  };

  toggleEditPlaneLayerVisibility = () => {
    this.setState((prevState) => {
      return { editPlaneLayerVisible: !prevState.editPlaneLayerVisible };
    }, this.updateLayers);
  };

  // TODO: add toggling of layer for Floor Plan

  isFeatureInfoListNull = (feature_info_list: any[]) => {
    // nothing has been populated yet
    if (!feature_info_list) {
      return true;
    }

    for (let i = 0; i < feature_info_list.length; i++) {
      const temp_feature_info = feature_info_list[i];
      if (MapConst.LINE_TYPE_STRING_NAME in temp_feature_info) {
        if (
          temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] !==
          MapConst.NULL_STRING_NAME
        ) {
          return false;
        }
      } else if (MapConst.POLYGON_TYPE_STRING_NAME in temp_feature_info) {
        if (
          temp_feature_info[MapConst.POLYGON_TYPE_STRING_NAME] !==
          MapConst.NULL_STRING_NAME
        ) {
          return false;
        }
      }
    }
    return true;
  };

  featureMenuClick = (
    action: string,
    index: number | undefined = this.state.featureMenu?.index
  ) => {
    const { selectedFeatureIndexes } = this.state;
    let { testFeatures, undoFeatures } = this.state;

    let show_dialog = !!index;
    if (index !== undefined && action === "delete") {
      if (
        !this.isFeatureInfoListNull(
          testFeatures.features[index].properties?.feature_info_list
        )
      ) {
        alert("Please make all LineType / PolygonType NULL before deleting");
      } else {
        const features = [...testFeatures.features];
        const selectedIndex = selectedFeatureIndexes.indexOf(index);

        undoFeatures = this.updateUndo(this.state.testFeatures);

        features.splice(index, 1);

        if (selectedIndex > -1) {
          selectedFeatureIndexes.splice(selectedIndex, 1);
        }

        if (selectedFeatureIndexes.length === 0) {
          show_dialog = false;
        }

        testFeatures = Object.assign({}, testFeatures, {
          features,
        });
      }
    } else if (action === "split") {
      // TODO
    } else if (action === "info") {
      show_dialog = true;
    } else if (action === "close") {
      show_dialog = false;
    }

    this.updateFeatureMenu(
      testFeatures,
      show_dialog,
      selectedFeatureIndexes,
      undoFeatures
    );
  };

  selectGeojsonType = (type: any) => {
    const { setGeojsonTypeAction } = this.props;
    setGeojsonTypeAction(type);
  };

  generateLayers = () => {
    const { editPlane, viewport, editableGeoJsonLayer, measureFeatures } =
      this.state;
    const pointCloudLayers = this.currentPointCloudLayers;

    const tooltipsLayer = new TextLayer({
      id: "tooltips",
      data: getMeasureTooltips(measureFeatures),
      getSize: TOOLTIP_FONT_SIZE,
      getColor: TOOLTIP_FONT_COLOR,
    });

    const layers = [
      ...pointCloudLayers,
      tooltipsLayer,
      edit_plane_layer(
        [viewport.longitude, viewport.latitude],
        editPlane.elevation,
        editPlane.radius,
        editPlane.color,
        editPlane.renderEditPlane
      ),
    ];

    if (editableGeoJsonLayer) {
      return [...layers, editableGeoJsonLayer];
    }

    return layers;
  };

  getCurrentEditableGeoJsonLayer = () => {
    const {
      testFeatures,
      selectedFeatureIndexes,
      mode,
      editHandleType,
      modeConfig,
      editPlane,
      editableGeoJsonLayerVisible,
      measureFeatures,
    } = this.state;

    const tooltips = {
      getColor: TOOLTIP_FONT_COLOR,
    };

    const editableGeoJsonLayer = new (EditableGeoJsonLayer as any)({
      ...MapConst.defaultGeoJsonLayerProps,
      data: {
        ...testFeatures,
        features: [...testFeatures.features, ...measureFeatures.features],
      },
      selectedFeatureIndexes,
      mode,
      modeConfig,
      onEdit: this.onEdit,
      editHandleType,
      getEditHandleIcon: (d: any) => getEditHandleTypeFromEitherLayer(d),
      getEditHandleIconColor: getEditHandleColor,
      getLineColor: this.getLineColor,
      getEditHandlePointColor: getEditHandleColor,
      editElevation: editPlane.elevation,
      visible: editableGeoJsonLayerVisible,
      _subLayerProps: {
        tooltips,
      },
    });

    return editableGeoJsonLayer;
  };

  removeLane = (laneId: number) => {
    const { removeLaneAction } = this.props;
    const { testFeatures } = this.state;
    const { features } = testFeatures;
    removeLaneAction(laneId);
    this.setState(
      {
        testFeatures: {
          ...testFeatures,
          features: getFilteredFeatures(features, laneId),
        },
      },
      () =>
        this.setState({
          editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
        })
    );
  };

  removeMeasureElements = (id: number) => {
    const { measureFeatures } = this.state;
    const { features } = measureFeatures;

    this.setState(
      {
        measureFeatures: {
          ...measureFeatures,
          features: features.filter(
            (feature) => feature?.properties?.id !== id
          ),
        },
        mode: CustomViewMode,
      },
      () =>
        this.setState({
          editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
        })
    );
  };

  verifySemanticMap = () => {
    const { testFeatures } = this.state;
    const { toggleModalAction } = this.props;

    toggleModalAction({
      type: VERIFY_SEMANTIC_MAP_MODAL,
      data: testFeatures,
    });
  };

  render() {
    const { currentMapData, toggleModalAction, mapStructs } = this.props;
    const {
      mode,
      viewport,
      selectedFeatureIndexes,
      selectionTool,
      editableGeoJsonLayer,
      contextMenuData,
      layerVisibility,
      editableGeoJsonLayerVisible,
      bufferFeature,
    } = this.state;

    let { modeConfig } = this.state;
    const { geojsonType } = currentMapData;

    if (mode === ElevationMode) {
      modeConfig = {
        ...modeConfig,
        viewport,
        calculateElevationChange: (opts: {
          pointerDownScreenCoords: Position;
          screenCoords: Position;
        }) =>
          ElevationMode.calculateElevationChangeWithViewport(viewport, opts),
      };
    } else if (mode === ModifyMode) {
      modeConfig = {
        ...modeConfig,
        viewport,
      };
    } else if (
      mode instanceof SnappableMode &&
      modeConfig &&
      modeConfig.enableSnapping
    ) {
      // Snapping can be accomplished to features that aren't rendered in the same layer
      modeConfig = {
        ...modeConfig,
        additionalSnapTargets: MapConst.modeConfigAdditionalSnapTargets,
      };
    }

    const layers = this.generateLayers();

    const isShowGeoJsonSelectTypeModal =
      this.currentPointCloudLayers.length !== 0 && !geojsonType;

    return (
      <div>
        {contextMenuData && (
          <ContextMenu
            contextMenuData={contextMenuData}
            toggleLayerVisibility={this.toggleLayerVisibility}
            layerVisibility={layerVisibility}
            editableGeoJsonLayerVisible={editableGeoJsonLayerVisible}
            toggleEditableGeoJsonLayerVisibility={
              this.toggleEditableGeoJsonLayerVisibility
            }
            addToClipboard={this.addToClipboard}
            bufferFeature={bufferFeature}
            pasteFromClipboard={this.pasteFromClipboard}
          />
        )}
        {this.currentPointCloudLayers.length === 0 && <LoaderSpinner />}
        {isShowGeoJsonSelectTypeModal && <GeoJsonSelectTypeModal />}
        <MapContainerStyled
          onMouseDown={this.handleMouseDown}
          onContextMenu={this.handleMouseDown}
        >
          <link
            href="https://api.mapbox.com/mapbox-gl-js/v0.44.0/mapbox-gl.css"
            rel="stylesheet"
          />
          {currentMapData.pointCloudJson && (
            <DeckGL
              viewState={viewport}
              getCursor={
                editableGeoJsonLayer
                  ? editableGeoJsonLayer.getCursor.bind(editableGeoJsonLayer)
                  : undefined
              }
              layers={layers}
              height="100%"
              width="100%"
              views={[
                new MapView({
                  id: "basemap",
                  controller: {
                    type: MapController,
                    doubleClickZoom: (mode as any) === "view" && !selectionTool,
                  },
                }),
              ]}
              onClick={this.onLayerClick}
              onViewStateChange={({ viewState }) => {
                this.setState({ viewport: viewState });
              }}
            >
              {this.renderStaticMap(viewport)}
            </DeckGL>
          )}
          {/* TODO: Inprove: Send only required variables */}
          <MapToolbox
            verifySemanticMap={this.verifySemanticMap}
            removeMeasureElements={this.removeMeasureElements}
            measureFeatures={this.state.measureFeatures}
            deleteFeatureProperty={this.deleteFeatureProperty.bind(this)}
            alterDisabled={selectedFeatureIndexes.length === 0}
            dispatch={this.props.dispatch}
            clearSelectedFeatures={this.clearSelectedFeatures}
            featureMenuClick={this.featureMenuClick}
            parseStringJson={this.parseStringJson}
            undo={this.undoFeature}
            updateFeatures={this.updateFeatures}
            updateModeConfig={this.updateModeConfig}
            updatePointsRemovable={this.updatePointsRemovable}
            updateSelectedFeatureIndexes={this.updateSelectedFeatureIndexes}
            updateRenderMapboxLayer={this.updateRenderMapboxLayer}
            updateShowGeoJson={this.updateShowGeoJson}
            updateMode={this.updateMode}
            updatePointCloudJson={this.updatePointCloudJson}
            toggleEditPlaneLayerVisibility={this.toggleEditPlaneLayerVisibility}
            isSemanticDataPresent={this.isSemanticDataPresent}
            isCenterlineDataPresent={this.isCenterlineDataPresent}
            onGeojsonTypeSelect={this.selectGeojsonType}
            geojsonType={geojsonType}
            toggleModalAction={toggleModalAction}
            currentMode={mode}
            modeConfig={modeConfig}
            pointcloudOrigin={this.state.pointcloudOrigin}
            pointsRemovable={this.state.pointsRemovable}
            renderMapboxLayer={this.state.renderMapboxLayer}
            selectedFeatureIndexes={this.state.selectedFeatureIndexes}
            showGeoJson={this.state.showGeoJson}
            testFeatures={this.state.testFeatures}
            undoFeatures={this.state.undoFeatures}
            useLocalCoord={this.state.useLocalCoord}
          />
          {/* TODO: Send only required props */}
          {this.state.featureMenu && (
            <FeatureMenu
              {...this.state}
              featureMenuClick={this.featureMenuClick}
            />
          )}
        </MapContainerStyled>
        <div>
          {/* TODO: Inprove: Send only required variables */}
          {/* uncomment to open a new modal window */}
          {/* {this.state.showDialog && (
            <FeatureInfoModal updateDialog={this.updateDialog} />
          )} */}
          {/* comment to open a new modal window */}
          <FeatureDialog
            {...this.state}
            deleteFeatureProperty={this.deleteFeatureProperty.bind(this)}
            selectedFeatureIndexes={selectedFeatureIndexes}
            showDialog={this.state.showDialog}
            testFeatures={this.state.testFeatures}
            lane={mapStructs.lanes[this.state.selectedLaneId]}
            featureMenuClick={this.featureMenuClick}
            updateDialog={this.updateDialog}
            updateFeatures={this.updateFeatures}
            updateSelectedFeatureIndexes={this.updateSelectedFeatureIndexes}
          />
          <RadiusDialog
            isError={this.getIsError()}
            radius={this.getRadius()}
            radiusDialog={this.state.radiusDialog}
            updateRadius={this.updateRadius}
          />
        </div>
        <div>
          <SideMenu
            renderEditPlane={this.state.editPlane.renderEditPlane}
            adjustEditPlaneElevation={this.adjustEditPlaneElevation.bind(this)}
            adjustEditPlaneRadius={this.adjustEditPlaneRadius.bind(this)}
            setEditPlaneOpen={this.setEditPlaneOpen.bind(this)}
            onLaneFocus={this.onLaneFocus.bind(this)}
            onIntersectionFocus={this.onIntersectionFocus.bind(this)}
            onStopSignFocus={this.onStopSignFocus.bind(this)}
            removeLane={(laneId: number) =>
              toggleModalAction({
                type: CONFIRM_ACTION_MODAL,
                data: {
                  text: "Are you sure you want to delete the entire lane?",
                  confirmAction: () => this.removeLane(laneId),
                },
              })
            }
          ></SideMenu>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentMapData: getCurrentMap(state),
  isLoading: getIsLoading(state),
  saveSemanticMapFlag: getSaveSemanticMapFlag(state),
  mapStructs: getMapStructs(state),
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    saveMapGeoJsonAction: (params: any) =>
      dispatch(fetchPatchMapGeoJson(params)),
    getCurrentMapDataAction: () => dispatch(fetchGetCurrentMapData()),
    setGeojsonTypeAction: (params: any) => dispatch(setGeojsonType(params)),
    toggleModalAction: (params: any) => dispatch(toggleModal(params)),
    setMapStructsAction: (params: any) => dispatch(setMapStructsData(params)),
    removeLaneAction: (params: any) => dispatch(removeLane(params)),
    setLaneAction: (params: any) => dispatch(setLane(params)),
    removeIntersectionAction: (params: any) =>
      dispatch(removeIntersection(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapEditorComponent);
