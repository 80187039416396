import { RootState } from "../..";
import {
  SAVE_PROGRESS_MODAL,
  REPORT_MODAL,
  VERSION_MODAL,
  CONFIRM_ACTION_MODAL,
  NEW_PROJECT_MODAL,
  EDIT_PROJECT_MODAL,
  POINT_CLOUD_MODAL,
  GEO_JSON_SELECT_TYPE_MODAL,
  VERIFY_GEOJSON_MODAL,
  VERIFY_SEMANTIC_MAP_MODAL,
} from "./constants";

export const getIsSaveProgressModal = (state: RootState) =>
  state.modals.type === SAVE_PROGRESS_MODAL;
export const getIsVerifyGeoJsonModal = (state: RootState) =>
  state.modals.type === VERIFY_GEOJSON_MODAL;
export const getIsVerifySemanticMapModal = (state: RootState) =>
  state.modals.type === VERIFY_SEMANTIC_MAP_MODAL;
export const getIsReportModal = (state: RootState) =>
  state.modals.type === REPORT_MODAL;
export const getIsVersionModal = (state: RootState) =>
  state.modals.type === VERSION_MODAL;
export const getIsConfirmActionModal = (state: RootState) =>
  state.modals.type === CONFIRM_ACTION_MODAL;
export const getIsNewProjectModal = (state: RootState) =>
  state.modals.type === NEW_PROJECT_MODAL;
export const getIsEditProjectModal = (state: RootState) =>
  state.modals.type === EDIT_PROJECT_MODAL;
export const getIsPointCloudModal = (state: RootState) =>
  state.modals.type === POINT_CLOUD_MODAL;
export const getIsGeoJsonSelectTypeModal = (state: RootState) =>
  state.modals.type === GEO_JSON_SELECT_TYPE_MODAL;
export const getIsModalOpen = (state: RootState) => Boolean(state.modals.type);
export const getModalData = (state: RootState) => state.modals.data;
