import { createAsyncThunk } from "@reduxjs/toolkit";

import { toggleModal, VERIFY_GEOJSON_MODAL } from "../modals";
import { MapStructs } from "../../../models/map-interface";
import { DataError } from "../../../utils/data";

import { setMapStructs } from ".";

export const setMapStructsData = createAsyncThunk(
  "appState/setMapStructsData",
  async (data: MapStructs & { errors: Array<DataError> }, thunkAPI) => {
    const { lanes, stopSigns, intersections, controlLines, errors } = data;

    if (errors && errors.length !== 0) {
      thunkAPI.dispatch(
        toggleModal({ type: VERIFY_GEOJSON_MODAL, data: errors })
      );
    }
    thunkAPI.dispatch(
      setMapStructs({ lanes, stopSigns, intersections, controlLines })
    );
  }
);
