import {
  getMapStructs,
  getAvailableIds,
  getOutputMapStructs,
} from "./selectors";
import mapStructs from "./reducers";
import { setMapStructsData } from "./actions";

// actions
export const {
  setMapStructs,
  resetMapStructs,
  resetSpeedLimits,
  setSpeedLimit,
  removeLane,
  removeIntersection,
  setLane,
  setStopSign,
  setControlLine,
  setIntersection,
  removeControlLine,
  removeStopSign,
  setNewLane,
} = mapStructs.actions;

// async actions
export { setMapStructsData };

// selectors
export { getMapStructs, getAvailableIds, getOutputMapStructs };

// reducer
export default mapStructs.reducer;
