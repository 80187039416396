import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { closeAnyModal, getModalData } from "../../../store/slices/modals";
import { ModalBackgroundStyled, ModalStyled } from "../styles";

import {
  VerifySemanticMapModalStyled,
  HeaderStyled,
  ErrorLineContainerStyled,
  ErrorLineStyled,
  ErrorLineHeaderStyled,
} from "./styles";

export const VerifyGeoJsonModal = memo(() => {
  const dispatch = useDispatch();
  const modalData = useSelector(getModalData);

  const handleCloseModal = useCallback(() => {
    dispatch(closeAnyModal());
  }, []);

  const errors = modalData && Array.isArray(modalData) ? modalData : [];

  return (
    <ModalStyled>
      <ModalBackgroundStyled onClick={handleCloseModal} />
      <VerifySemanticMapModalStyled>
        <HeaderStyled>Checking for errors in uploaded geoJSON</HeaderStyled>
        <ErrorLineContainerStyled>
          <ErrorLineHeaderStyled>Errors found:</ErrorLineHeaderStyled>
          {errors.map(({ text }) => (
            <ErrorLineStyled key={text}>{text}</ErrorLineStyled>
          ))}
        </ErrorLineContainerStyled>
      </VerifySemanticMapModalStyled>
    </ModalStyled>
  );
});
