import React, { memo } from "react";

import { ReactComponent as LogoSvg } from "./assets/logo.svg";
// import { ReactComponent as BackIcon } from "./assets/backIcon.svg";
// import { UserInfo } from "./components/UserInfo";
// import { EditorToolbar } from "./components/EditorToolbar";
import ContextMenu from "./components/ContextMenu";
// import { CurrentMapInfo } from "./components/CurrentMapInfo";
import {
  HeaderStyled,
  LogoContainerStyled,
  ContentContainerStyled,
  // BackToProjectsButtonStyled,
} from "./styles";

const Header = memo(() => {
  return (
    <HeaderStyled>
      <ContentContainerStyled>
        <LogoContainerStyled data-test-id="home">
          <LogoSvg />
        </LogoContainerStyled>
        {/* <BackToProjectsButtonStyled>
          <BackIcon />
          Back to Projects
        </BackToProjectsButtonStyled> */}
        {/* <EditorToolbar /> */}
      </ContentContainerStyled>
      {/* <ContentContainerStyled>
        <CurrentMapInfo />
      </ContentContainerStyled> */}
      <ContentContainerStyled>
        {/* <UserInfo /> */}
        <ContextMenu />
      </ContentContainerStyled>
    </HeaderStyled>
  );
});

export default Header;
