import React, { memo, useCallback, useState } from "react";

import { ReactComponent as ArrowIcon } from "../../assets/arrow.svg";
import { ReactComponent as CopyIcon } from "../../assets/copyIcon.svg";
import { ReactComponent as PasteIcon } from "../../assets/pasteIcon.svg";

import {
  ContextMenuBodyStyled,
  ContextMenuItemStyled,
  ContextMenuItemContentStyled,
  ContextMenuOptionsStyled,
  ContextMenuOptionsItemStyled,
  LayerIconStyled,
} from "./styles";

export type ContextMenuDataType = {
  position: Array<number>;
  coordinate: Array<number>;
  currentBufferFeature?: number;
};

type ContextMenuProps = {
  contextMenuData: ContextMenuDataType;
  layerVisibility: { [key: string]: boolean };
  toggleLayerVisibility: (id: string) => void;
  editableGeoJsonLayerVisible: boolean;
  toggleEditableGeoJsonLayerVisibility: () => void;
  bufferFeature: number | null;
  addToClipboard: (featureIndex: number) => void;
  pasteFromClipboard: (position: Array<number>) => void;
};

export const ContextMenu: React.FC<ContextMenuProps> = memo(
  ({
    contextMenuData,
    layerVisibility,
    toggleLayerVisibility,
    editableGeoJsonLayerVisible,
    toggleEditableGeoJsonLayerVisibility,
    bufferFeature,
    addToClipboard,
    pasteFromClipboard,
  }) => {
    const [isLayerMenuOpen, setIsLayerMenuOpen] = useState(false);
    const { position, currentBufferFeature, coordinate } = contextMenuData;

    const copyAction = useCallback(() => {
      if (currentBufferFeature && currentBufferFeature !== null) {
        addToClipboard(currentBufferFeature);
      }
    }, [currentBufferFeature, addToClipboard]);

    const pasteAction = useCallback(() => {
      if (bufferFeature === null) return;
      pasteFromClipboard(coordinate);
    }, [bufferFeature, pasteFromClipboard]);

    return (
      <ContextMenuBodyStyled x={position[0]} y={position[1]}>
        <ContextMenuItemStyled
          onClick={copyAction}
          isDisabled={!currentBufferFeature || currentBufferFeature === null}
        >
          <ContextMenuItemContentStyled>
            Copy
            <CopyIcon />
          </ContextMenuItemContentStyled>
        </ContextMenuItemStyled>
        <ContextMenuItemStyled
          onClick={pasteAction}
          isDisabled={bufferFeature === null}
        >
          <ContextMenuItemContentStyled>
            Paste here
            <PasteIcon />
          </ContextMenuItemContentStyled>
        </ContextMenuItemStyled>
        <ContextMenuItemStyled
          onMouseEnter={() => setIsLayerMenuOpen(true)}
          onMouseLeave={() => setIsLayerMenuOpen(false)}
        >
          <ContextMenuItemContentStyled>
            Select layer
            <ArrowIcon />
          </ContextMenuItemContentStyled>
          {isLayerMenuOpen && (
            <ContextMenuOptionsStyled>
              <ContextMenuOptionsItemStyled
                onClick={() => toggleEditableGeoJsonLayerVisibility()}
              >
                <LayerIconStyled isVisible={editableGeoJsonLayerVisible} />
                Editable GeoJson Layer
              </ContextMenuOptionsItemStyled>
              {Object.entries(layerVisibility).map(([layerId, isVisible]) => {
                const index = layerId
                  ? parseInt(layerId.split("_").pop() || "0") + 1
                  : 0;
                return (
                  <ContextMenuOptionsItemStyled
                    key={layerId}
                    onClick={() => toggleLayerVisibility(layerId)}
                  >
                    <LayerIconStyled isVisible={isVisible} /> 3D point Layer
                    {index}
                  </ContextMenuOptionsItemStyled>
                );
              })}
            </ContextMenuOptionsStyled>
          )}
        </ContextMenuItemStyled>
      </ContextMenuBodyStyled>
    );
  }
);
